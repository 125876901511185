






































import Vue from 'vue'
const fanhanapphead = Vue.extend({
  computed: {
    bgImage () {
      const lang = this.$i18n.locale
      const name = lang === 'zh' ? 'bg' : 'bg-en'
      return require(`@/assets/image/app/${name}.png`)
    }
  }
})
export default fanhanapphead
