<template>
  <div class="steps-wrap">
    <div class="steps container section-box">
      <div class="steps-title">
        <img src="~@/assets/image/app/steps-title.png">
        <div class="text">{{$t('fanhanapp.howdoTitle')}}</div>
      </div>
      <div class="steps-first">
        <StepsTitle num="1" :title="$t('fanhanapp.step1')"/>
        <StepsBox :href="curImage[0]"/>
      </div>
      <div class="steps-second">
        <StepsTitle num="2" :title="$t('fanhanapp.step2')"/>
        <div class="items-wrap">
          <StepsBox num="1" :content="$t('fanhanapp.step2_1')" :href="curImage[1]"/>
          <StepsBox num="2" :content="$t('fanhanapp.step2_2')" :href="curImage[2]"/>
        </div>
      </div>
      <div class="steps-first">
        <StepsTitle num="3" :title="$t('fanhanapp.step3')"/>
        <StepsBox :href="curImage[3]"/>
      </div>
      <div class="steps-first">
        <StepsTitle num="4" :title="$t('fanhanapp.step4')"/>
        <StepsBox :href="curImage[4]"/>
      </div>
      <div class="steps-first">
        <StepsTitle num="5" :title="$t('fanhanapp.step5')"/>
        <StepsBox :href="curImage[5]"/>
      </div>
      <div class="steps-second">
        <StepsTitle num="6" :title="$t('fanhanapp.step6')"/>
        <div class="items-wrap">
          <StepsBox :href="curImage[6]"/>
          <StepsBox :href="curImage[7]"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepsTitle from './StepsTitle'
import StepsBox from './StepsBox'
export default {
  name: 'fanhanappsteps',
  components: {
    StepsTitle,
    StepsBox
  },
  data () {
    return {
      en: [
        require('@/assets/image/app/steps1.en.png'),
        require('@/assets/image/app/steps2-1.en.png'),
        require('@/assets/image/app/steps2-2.en.png'),
        require('@/assets/image/app/steps3.en.png'),
        require('@/assets/image/app/steps4.en.png'),
        require('@/assets/image/app/steps5.en.png'),
        require('@/assets/image/app/steps6-1.en.png'),
        require('@/assets/image/app/steps6-2.en.png')
      ],
      zh: [
        require('@/assets/image/app/steps1.png'),
        require('@/assets/image/app/steps2-1.png'),
        require('@/assets/image/app/steps2-2.png'),
        require('@/assets/image/app/steps3.png'),
        require('@/assets/image/app/steps4.png'),
        require('@/assets/image/app/steps5.png'),
        require('@/assets/image/app/steps6-1.png'),
        require('@/assets/image/app/steps6-2.png')
      ],
      curImage: []
    }
  },
  created () {
    this.curImage = this.zh
  },
  watch: {
    '$i18n.locale' (n) {
      const lang = n === 'zh' ? 'zh' : 'en'
      this.curImage = this[lang]
    }
  }
}
</script>

<style lang="less" scoped>
.steps-wrap {
  background-color: #f2f2f2;
}
.steps {
  color: #666666;
  overflow: hidden;
  .steps-title {
    display: flex;
    align-items: flex-end;
    img {
      height: 2.5rem;
    }
    .text {
      font-size: 1.8rem;
      line-height: 2rem;
      margin-left: 1rem;
      color: #464545;
      font-weight: bold;
    }
  }
}
.steps-first, .steps-second {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
}
.items-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1200px) {
  .items-wrap {
    flex-direction: column;
  }
}
@media screen and (max-width: 768px) {
  .steps .steps-title {
    img {
      height: 22px;
    }
    .text {
      font-size: 18px;
      line-height: 18px;
    }
  }
}
</style>
