<template>
  <div id="app">
    <Header :bcgBlack="true"></Header>
    <FanhanAppHead></FanhanAppHead>
    <FanhanAppSteps></FanhanAppSteps>
    <Footer></Footer>
    <BackTop></BackTop>
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
import FanhanAppHead from './components/FanhanAppHead'
import FanhanAppSteps from './components/FanhanAppSteps'
import BackTop from '@/components/BackTop'
export default {
  name: 'App',
  components: {
    Header,
    Footer,
    BackTop,
    FanhanAppHead,
    FanhanAppSteps
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
